<template>
  <div class="box">
    <div class="left leftDetail">
      <img class="leftBg" v-if="!id" src="../assets/images/Contact.png">
      <div class="leftImg">
        <navHead />
        <div class="leftTxt" v-if="!id">
          Contact
        </div>
      </div>
    </div>
    <div class="right rightDetail" :class="{'detail' : id}">
      <div class="rightTitle" v-if="!id">
        <p>
          <b>Lionox Energy and Engineering Aps</b><br />
          <span>
            Lundsgade 9, st. th, 2100<br />
            København Ø, Denmark<br />
            +45 53649113<br />
            info@lionox.co
            </span>
        </p>
        <p>
          <b>Lab in China</b><br />
          <span>
            Lionox Huavia Co.Ltd<br />
            Jin Gu Road, Bin Hai District<br />
            Tiajin China<br />
            +86 22 25312048<br />
            info@lionox.co
          </span>
        </p>
        <p>
          <b>Lionox China office</b><br />
          <span>
            No.11 Commercial building<br />
            Royal garden, Huang Hai Road No.10<br />
            TEDA, Tianjin<br />
            +45 86 13920449113<br />
            info@lionox.co
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>

import navHead from '/src/components/navHead'

export default {
  name: 'Contact',
  components: {
    navHead
  },
  data(){
    return{
      id: null
    }
  },
  mounted(){
    this.id = this.$route.query.id || null
  },
  methods:{
    goBack(){
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
.rightTitle b{
  font-size: 24px;
}
.rightTitle span{
  font-size: 16px;
}
</style>
